import {
  DownloadSourceFileGqlQuery,
  DownloadSourceFileGqlStatus,
  SourceFileDeviceNanoId,
  SourceFileGqlError,
  SourceFileUUID
} from '../../sourceFilesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadSourceFileOptions {
  query: DownloadSourceFileGqlQuery;
}

export interface DownloadSourceFileResponse<DownloadSourceFileRecordType> {
  downloadSourceFile: {
    status: DownloadSourceFileGqlStatus;
    recordUuid: SourceFileUUID;
    record: DownloadSourceFileRecordType;
    errors: DownloadSourceFileError;
  };
}

export interface DownloadSourceFileInput {
  uuid: SourceFileUUID;
  deviceNanoId: SourceFileDeviceNanoId;
}

export interface DownloadSourceFileError {
  fullMessages: SourceFileGqlError;
}

const action = 'downloadSourceFile';

function useDownloadSourceFile<DownloadSourceFileRecordType>({
  query
}: DownloadSourceFileOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadSourceFileInput,
    DownloadSourceFileResponse<DownloadSourceFileRecordType>,
    DownloadSourceFileError,
    DownloadSourceFileRecordType
  >({ action, query });

  return {
    downloadSourceFileData: updateQueryData,
    downloadSourceFileError: updateQueryError,
    downloadSourceFileLoading: updateQueryLoading,
    downloadSourceFileErrorMessage: updateQueryErrorMessage,
    downloadSourceFile: updateQuery,
    downloadSourceFileReset: updateQueryReset
  };
}

export default useDownloadSourceFile;
