import {
  DownloadFileAttachmentGqlQuery,
  DownloadFileAttachmentGqlStatus,
  FileAttachmentDeviceNanoId,
  FileAttachmentGqlError,
  FileAttachmentUUID
} from '../../fileAttachmentsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadFileAttachmentOptions {
  query: DownloadFileAttachmentGqlQuery;
}

export interface DownloadFileAttachmentResponse<
  DownloadFileAttachmentRecordType
> {
  downloadFileAttachment: {
    status: DownloadFileAttachmentGqlStatus;
    recordUuid: FileAttachmentUUID;
    record: DownloadFileAttachmentRecordType;
    errors: DownloadFileAttachmentError;
  };
}

export interface DownloadFileAttachmentInput {
  uuid: FileAttachmentUUID;
  deviceNanoId: FileAttachmentDeviceNanoId;
}

export interface DownloadFileAttachmentError {
  fullMessages: FileAttachmentGqlError;
}

const action = 'downloadFileAttachment';

function useDownloadFileAttachment<DownloadFileAttachmentRecordType>({
  query
}: DownloadFileAttachmentOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadFileAttachmentInput,
    DownloadFileAttachmentResponse<DownloadFileAttachmentRecordType>,
    DownloadFileAttachmentError,
    DownloadFileAttachmentRecordType
  >({ action, query });

  return {
    downloadFileAttachmentData: updateQueryData,
    downloadFileAttachmentError: updateQueryError,
    downloadFileAttachmentLoading: updateQueryLoading,
    downloadFileAttachmentErrorMessage: updateQueryErrorMessage,
    downloadFileAttachment: updateQuery,
    downloadFileAttachmentReset: updateQueryReset
  };
}

export default useDownloadFileAttachment;
