import { useCallback, useMemo } from 'react';

import { S3MultipartFileType } from '../../s3MultipartTypes';

import {
  DownloadClientFileQueryResponse,
  DOWNLOAD_CLIENT_FILE_QUERY
} from '../../../clientFiles/queries/downloadClientFile.query';
import {
  DownloadFileAttachmentQueryResponse,
  DOWNLOAD_FILE_ATTACHMENT_QUERY
} from '../../../fileAttachments/queries/downloadFileAttachment.query';
import {
  DownloadImageQueryResponse,
  DOWNLOAD_IMAGE_QUERY
} from '../../../images/queries/downloadImage.query';
import {
  DownloadSourceFileQueryResponse,
  DOWNLOAD_SOURCE_FILE_QUERY
} from '../../../sourceFiles/queries/downloadSourceFile.query';
import {
  DownloadMaxFileQueryResponse,
  DOWNLOAD_MAX_FILE_QUERY
} from '../../../maxFiles/queries/downloadMaxFile.query';

import { useDownloadClientFile } from '../../../clientFiles/hooks/useDownloadClientFile';
import { useShowToastOnErrorChange } from '../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadNanoId } from '../../../downloads/hooks/useDownloadNanoId';
import { useDownloadManagerBlockState } from '../../../downloads/hooks/useDownloadManagerBlockState';
import { useDownloadFileAttachment } from '../../../fileAttachments/hooks/useDownloadFileAttachment';
import { useDownloadImage } from '../../../images/hooks/useDownloadImage';
import { useDownloadSourceFile } from '../../../sourceFiles/hooks/useDownloadSourceFile';
import { useDownloadMaxFile } from '../../../maxFiles/hooks/useDownloadMaxFile';
import { DownloadTypeFileOnDownload } from './useDownloadTypeFile.types';

interface DownloadTypeFileProps {
  type: S3MultipartFileType;
}

function useDownloadTypeFile({ type }: DownloadTypeFileProps) {
  const { downloadClientFile, downloadClientFileErrorMessage } =
    useDownloadClientFile<DownloadClientFileQueryResponse>({
      query: DOWNLOAD_CLIENT_FILE_QUERY
    });

  const { downloadFileAttachment, downloadFileAttachmentErrorMessage } =
    useDownloadFileAttachment<DownloadFileAttachmentQueryResponse>({
      query: DOWNLOAD_FILE_ATTACHMENT_QUERY
    });

  const { downloadImage, downloadImageErrorMessage } =
    useDownloadImage<DownloadImageQueryResponse>({
      query: DOWNLOAD_IMAGE_QUERY
    });

  const { downloadSourceFile, downloadSourceFileErrorMessage } =
    useDownloadSourceFile<DownloadSourceFileQueryResponse>({
      query: DOWNLOAD_SOURCE_FILE_QUERY
    });

  const { downloadMaxFile, downloadMaxFileErrorMessage } =
    useDownloadMaxFile<DownloadMaxFileQueryResponse>({
      query: DOWNLOAD_MAX_FILE_QUERY
    });

  const downloadActions = useMemo(
    () => ({
      clientFiles: downloadClientFile,
      fileAttachments: downloadFileAttachment,
      images: downloadImage,
      sourceFiles: downloadSourceFile,
      maxFiles: downloadMaxFile
    }),
    [
      downloadClientFile,
      downloadFileAttachment,
      downloadImage,
      downloadSourceFile,
      downloadMaxFile
    ]
  );

  const downloadErrorMessage = useMemo(
    () => ({
      clientFiles: downloadClientFileErrorMessage,
      fileAttachments: downloadFileAttachmentErrorMessage,
      images: downloadImageErrorMessage,
      sourceFiles: downloadSourceFileErrorMessage,
      maxFiles: downloadMaxFileErrorMessage
    }),
    [
      downloadClientFileErrorMessage,
      downloadFileAttachmentErrorMessage,
      downloadImageErrorMessage,
      downloadSourceFileErrorMessage,
      downloadMaxFileErrorMessage
    ]
  );

  useShowToastOnErrorChange({ error: downloadErrorMessage[type] });

  const { downloadNanoId } = useDownloadNanoId();
  const { openDownloadManagerBlock } = useDownloadManagerBlockState();

  const onDownload = useCallback<DownloadTypeFileOnDownload>(
    (uuid) => {
      downloadActions[type]?.({
        uuid,
        deviceNanoId: downloadNanoId
      }).then(() => openDownloadManagerBlock());
    },
    [downloadActions, downloadNanoId, openDownloadManagerBlock, type]
  );

  return {
    onDownload
  };
}

export default useDownloadTypeFile;
